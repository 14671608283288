import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
//
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert";
import { connection } from "../helper/connection";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../reducers/Actions";
import DefiqStakeABI from "../ABI/StakeContract.json";
import {
  useConnect,
  useWalletClient,
  useAccount,
  usePublicClient,
  useDisconnect,
  useNetwork,
  useSwitchNetwork
} from "wagmi";
import {
  formatAddress,
  toFixedFormat,
  toFixedWithoutRound
} from "../helper/customMath";
import { useEthersSigner, walletClientToSigner } from "../helper/ethersconnect";
import Web3 from "web3";
import { getTokenBalance } from "../Action/contractActions";

var initialvalue = {
  UsdtBalance: 0,
  DefiQBalance: 0
};

export default function Navbar({ addressparam }) {
  const [metamaskenable, setmetamaskenable] = useState(true);
  const { connect, connectors } = useConnect();
  const { chain } = useNetwork();
  let chainId = config.NetworkId;
  const dispatch = useDispatch();
  const [useraddress, setuseraddress] = useState("");
  const [balance, setbalance] = useState("");
  const location = useLocation();
  const [tokenBalance, setTokenBalance] = useState(initialvalue);
  const { disconnect, isSuccess, status } = useDisconnect();
  const { data: walletClient } = useWalletClient({ chainId });
  const walletConnection = useSelector((state) => state.walletConnection);
  console.log("walletConnectioonononnnnn: ", walletConnection);
  const { UsdtBalance, DefiQBalance } = tokenBalance;

  useEffect(() => {
    getuserBalance();
    gettokenBalancee();
  }, [walletConnection, walletClient]);

  async function getuserBalance() {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != ""
    ) {
      setuseraddress(walletConnection.address);
      var web3 = walletConnection.web3;
      var getBalance = await web3.eth.getBalance(walletConnection.address);
      console.log(getBalance, "getBalancegetBalance");
      var bal = getBalance / 1e18;
      bal = await toFixedWithoutRound(bal);
      setbalance(bal);
    }
  }

  async function gettokenBalancee() {
    let defiBalce = await getTokenBalance(config.DefiQToken);
    let usdtBalce = await getTokenBalance(config.USDTAddress);
    setTokenBalance({
      UsdtBalance: usdtBalce?.balance ? usdtBalce?.balance : 0,
      DefiQBalance: defiBalce?.balance ? defiBalce?.balance : 0
    });
  }
  const network = useSwitchNetwork({
    onMutate(args) {
      console.log("Mutate", args);
    },
    onError(error) {
      console.log("Error", error);
    }
  });

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log(accounts, "accountsef");
        window.location.reload();
      });
    }
  }, []);

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      localStorage.clear();
      console.log("Disconnected");
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no"
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    }
  });

  // useEffect(() => {
  //   if (isSuccess == true) {
  //     setTimeout(() => {
  //       localStorage.clear();
  //       window.location.reload(false);
  //     }, 500);
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    if (chain && chain.id !== config.NetworkId) {
      dispatch(
        setWallet({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "",
          isChange: "true"
        })
      );
    } else {
      dispatch(
        setWallet({
          network: chainId,
          web3: walletConnection.web3,
          address: walletConnection.address,
          provider: walletConnection.provider,
          connect: "yes",
          isChange: "false"
        })
      );
    }
  }, [walletClient, chain?.network]);

  useEffect(() => {
    setConnection();
  }, [walletConnection.network, walletClient]);

  async function setConnection() {
    if (chain && chain.id != config.NetworkId) {
      var errorMsg = "Please select " + config.networkName + " on your wallet";
      toastAlert("error", errorMsg, "network");
    }
    if (walletClient && chain && chain.id == config.NetworkId) {
      var { signer, transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);
      var DefiqStakeCont = new web3.eth.Contract(
        DefiqStakeABI,
        config.DefiqStakeAddrs
      );
      let useraddress = addressparam
        ? addressparam
        : walletClient.account.address;
      let userDetails = await DefiqStakeCont.methods.users(useraddress).call();

      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: addressparam ? addressparam : walletClient.account.address,
          provider: transport,
          connect: "yes",
          isChange: "false",
          userDetails: userDetails
        })
      );
      // getuserBalance();
      // var get = await connection();
    } else {
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: null,
          address: "",
          provider: null,
          connect: "yes",
          isChange: "false"
        })
      );
    }
  }

  async function connectfunction(connector) {
    try {
      let check = isMobile();
      console.log(connector, "connectorconnector");
      if (
        check &&
        !window.ethereum &&
        connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toastAlert("error", "Please connect smart chain");
          return;
        }
      }

      var resp = connect(connector);
      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        console.log("Reload");
        // window.location.reload(false);
      }, 1000);
    } catch (err) {}
  }

  // async function connectfunction(connector) {
  //   // console.log(connector, 'vvvvvvvvvvvv')
  //   try {
  //     let check = isMobile();

  //     if (
  //       check &&
  //       !window.ethereum &&
  //       connector &&
  //       connector.connector.id != "walletConnect"
  //     ) {
  //       await connectMetamaskMobile();
  //       return;
  //     }
  //     var resp = connect(connector);
  //     if (chain && chain.id != config.NetworkId) {
  //       var errorMsg =
  //         "Please select " + config.networkName + " on your wallet";
  //       toastAlert("error", errorMsg, "network");
  //     }
  //   } catch (err) {
  //     console.log(err, "errrr");
  //   }
  // }

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    console.log("dappaaaaaaaaaaaaaaaaUrl: ", dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  useEffect(() => {
    getuserBalance();
    gettokenBalancee();
  }, [walletConnection, walletClient]);

  async function getuserBalance() {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != ""
    ) {
      setuseraddress(walletConnection.address);
      var web3 = walletConnection.web3;
      var getBalance = await web3.eth.getBalance(walletConnection.address);
      console.log(getBalance, "getBalancegetBalance");
      var bal = getBalance / 1e18;
      bal = await toFixedWithoutRound(bal);
      setbalance(bal);
    }
  }

  async function gettokenBalancee() {
    let defiBalce = await getTokenBalance(config.DefiQToken);
    let usdtBalce = await getTokenBalance(config.USDTAddress);
    setTokenBalance({
      UsdtBalance: usdtBalce?.balance ? usdtBalce?.balance : 0,
      DefiQBalance: defiBalce?.balance ? defiBalce?.balance : 0
    });
  }

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                <div className="menu mx-auto">
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <NavLink to="/" className="d-block">
                      {" "}
                      Home
                    </NavLink>
                  </div>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Staking
                    </button>
                    <ul class="dropdown-menu">
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="/staking">Staking</NavLink>
                      </li>
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="/mystaking">My Staking</NavLink>
                      </li>
                    </ul>
                  </div>
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link
                      to="faq"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      className="d-block"
                      style={{ cursor: "pointer" }}
                    >
                      FAQ
                    </Link>
                  </div>
                  {/*<div data-bs-dismiss="offcanvas" aria-label="Close">
                    <a
                      href="https://stakingapi.defiq.live/images/DefiQ_lite_paper.pdf"
                      target="_blank"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      className="d-block"
                    >
                      Lite Paper
                    </a>
                  </div> */}
                </div>

                {/* After Login Menus */}
                {walletConnection &&
                walletConnection.address &&
                walletConnection.address != "" &&
                walletConnection.address != null &&
                walletConnection.connect == "yes" ? (
                  <>
                    <div className="dropdown me-3">
                      <a
                        className="primary_btn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {formatAddress(useraddress)}
                      </a>
                      <ul
                        className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>{`${config.BNBSYMBOL} Balance:`} </label>
                            <span className="ms-2">{balance} </span>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>USDT Balance:</label>
                            <span className="ms-2">{UsdtBalance} </span>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>Defiq Balance:</label>
                            <span className="ms-2">{DefiQBalance} </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button
                      className="primary_btn"
                      onClick={() => disconnect()}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <div
                      className="wid"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <button
                        className="primary_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                      >
                        <div className="icon"></div>
                        Connect Wallet
                      </button>
                    </div>
                  </>
                )}

                {/* <button className="primary_btn"  >
                <img src={require("../assets/images/wallet.png")} class="img-fluid" alt="img" />
                  1Lbcfr7sAHTD....</button> */}
                {/* <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector) => (
                      <>
                        {metamaskenable &&
                          connector &&
                          connector.id == "metaMask" && (
                            <a target="_blank" className="mb-3 d-block">
                              <button
                                type="button"
                                className="connect_wallet_button"
                                key={connector.id}
                                onClick={() => connectfunction({ connector })}
                              >
                                <img
                                  src={require("../assets/images/metamask.png")}
                                  height="28"
                                  width="28"
                                  className="img-fluid"
                                />{" "}
                                <span>MetaMask</span>
                              </button>
                            </a>
                          )}
                        {connector.id == "walletConnect" && (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              type="button"
                              className="connect_wallet_button"
                              key={connector.id}
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/trustwallet.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>Trust Wallet</span>
                            </button>
                          </a>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
